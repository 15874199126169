import { styled, Typography } from '@mui/material'

export const HeadingText = styled(Typography)(({ theme }) => ({
    color: '#13140F', 
    fontWeight: '600', 
    fontStyle: 'normal', 
    fontSize: '40px', 
    fontFamily: '"Poppins", Helvetica',
    margin: '0px',
    lineHeight: '1.4'
  }))

  export const ParagraphText = styled(Typography)(({ theme }) => ({
    fontStyle: 'normal', 
    fontSize: '17px', 
    fontWeight: '400', 
    color: '#7c7c7c',
    fontFamily: '"Poppins", Helvetica', 
    margin: '0px'
  }))