"use client";
import React from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import NavbarLinks from '../static-data/navbar.json'
import Logo_img from "../../public/logo.svg"
import Image from 'next/image'
import { variables } from '../styles/variables';
import { Box } from '@mui/material';

const NavbarContainer = styled.nav`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: background: var(--colors-grey-0, #FFFFFF);
  color: black;
  position: relative;

  @media (max-width: 768px) {
    padding: 0.5rem;
    justify-content: space-between;
  }
`;

const Logo = styled.div`
  font-size: 1.5rem;
  font-weight: bold;

  a {
    color: white;
    text-decoration: none;
  }
  img {
    height: 40px;
    display: block;
  }
`;

const NavItems = styled.ul`
  list-style: none;
  display: flex;
  gap: 1rem;

  li {
    a {
      color: #1E1E1E;
      text-decoration: none;
      padding: 0.5rem 1rem;
      display: block;
      font-weight: 700;
      font-size: 12px;
      text-transform: uppercase;
      font-family: 'Poppins',Helvetica;
    }
  }

  @media (max-width: 768px) {
    z-index: 99999;
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 0;
    background-color: white;
    width: 100%;
    display: ${props => (props.showMenu ? '' : 'none')};
    height: 100vh;
  }
`;

const BurgerMenu = styled.div`
  display: none;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
  }

  span {
    display: block;
    width: 25px;
    height: 3px;
    background-color: black;
    margin: 5px 0;
    transition: all 0.3s;
  }
`;

const NavButton = styled.div`
  display: block;
  cursor: pointer;
  font-size: 12px;
  font-weight: 700;
  font-family: ${variables.fontFamily};
  padding: 10px;
  border-radius: 5px;
  border: 1px solid transparent;

  @media (max-width: 768px) {
    display: none;
  }

  span {
    display: block;
    width: 25px;
    height: 3px;
    background-color: black;
    margin: 5px 0;
    transition: all 0.3s;
  }
`;

const NavMenu = styled.div`
  color: #1E1E1E;
  text-decoration: none;
  padding: 0.5rem 1rem;
  display: block;
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  font-family: 'Poppins',Helvetica;
  cursor: pointer;
`

const Navbar = () => {
  const [showMenu, setShowMenu] = React.useState(false);
  const router = useRouter();

  const handleRedirect = (href) => {
    if (href) {
      if (href === "about" || href === "how-it-works") {
        router.push('/#' + href);
      } else if (href === '/') {
        router.push('/');
      } else {
        const element = document.getElementById(href);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    } else {
      window.open('https://onelink.to/s5pgev', '_blank');
    }
  };

  const handleFranchise = () => {
    router.push('/franchise');
  }

  return (
    <NavbarContainer>
      <Logo onClick={()=>{handleRedirect("/")}}>
        <Image
          src={Logo_img}
          alt="Meditagg"
          style={{ cursor: 'pointer' }}
          priority
        />
      </Logo>
      <BurgerMenu style={{ color: 'white', background: `${variables.primaryColor}`, padding: '10px', borderRadius: '5px' }} onClick={() => { handleRedirect() }}>DOWNLOAD APP</BurgerMenu>
      <BurgerMenu onClick={() => setShowMenu(!showMenu)}>
        <span />
        <span />
        <span />
      </BurgerMenu>
      <NavItems showMenu={showMenu}>
        {NavbarLinks.navbarLinks.map((link, index) => (
          <li key={index}>
            <NavMenu onClick={() => { handleRedirect(link.href) }}>{link.name}</NavMenu>
          </li>
        ))}
        <Box sx={{ display: { xs: 'block', sm: 'none', md: 'none' } }}>
          <BurgerMenu style={{ color: `${variables.primaryColor}`, background: 'white', border: `1px solid ${variables.primaryColor}`, width: '240px', padding: '16px', fontFamily: `${variables.fontFamily}`, cursor: 'pointer', fontSize: '12px',fontWeight: '700', borderRadius: '5px' }} onClick={() => { handleFranchise() }}>
            BECOME OUR PARTNER
          </BurgerMenu>
        </Box>
      </NavItems>
      <div style={{ display: 'flex', gap: '16px' }}>
        <NavButton style={{ color: `${variables.primaryColor}`, background: 'white', border: `1px solid ${variables.primaryColor}` }} onClick={() => { handleFranchise() }}>
          BECOME OUR PARTNER
        </NavButton>
        <NavButton style={{ color: 'white', background: `${variables.primaryColor}` }} onClick={() => { handleRedirect() }}>
          DOWNLOAD APP
        </NavButton>
      </div>
    </NavbarContainer>
  );
};

export default Navbar;
