import React from 'react';
import styled from 'styled-components';

const TopBarContainer = styled.div`
  background: #000000;
  color: white;
  overflow: hidden;
  white-space: nowrap;
  padding: 10px 0;
  position: relative;
  height: 40px;
`;

const ScrollingText = styled.div`
  display: inline-block;
  padding-left: 100%;
  animation: scroll-left 45s linear infinite;

  @keyframes scroll-left {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
`;

const CityList = styled.div`
  display: inline-block;
  padding: 0 5rem;
  font-size: 16px;
  font-weight: bold;
  font-family: 'Poppins', Helvetica;
`;

const cities = ["Pattambi", "Trichy", "Palakkad", "Coimbatore", "Mettupalayam"];

export default function TopBar() {
  return (
    <TopBarContainer>
      <ScrollingText>
        {'📍'}
        {cities.map((city, index) => (
          <React.Fragment key={index}>
            <CityList>{city}</CityList>
            {index < cities.length - 1 && '📍'}
          </React.Fragment>
        ))}
        {'📍'}
      </ScrollingText>
    </TopBarContainer>
  );
}
